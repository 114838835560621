
// Body
$body-bg: #e3e3e3;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Define Breakpoints to use as include (see respond-to mixin)
$breakpoints: (
        'xs':     ( min-width: 319px  ),
        'tiny':   ( min-width:  479px ),
        'small':  ( min-width:  767px ),
        'medium': ( min-width:  992px ),
        'large':  ( min-width: 1200px )
) !default;


// Brands
$brand-primary: #014698;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #333333;
$font-size-h1: 24px;
$font-size-h2: 20px;
$headings-font-weight: 700;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
