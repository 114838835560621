.notice-bar {
  background-color: $brand-primary;
  font-weight:700;
  text-align: center;
  color:#FFF;
  font-size:18px;
  padding:18px 20px;
}

.about-section {
  background: url('/img/layout/background-logo.png') 60% 100% no-repeat #f4f4f4;
  div {
    box-sizing: border-box;
  }

  .left-container {
    width:100%;
    margin-bottom:60px;

    @include respond-to('small') {
      width: 60%;
      margin-bottom:0;
      padding-right: 40px;
      float: left;
    }
  }

  .right-container {
    @include respond-to('small') {
      border-left: 2px solid $brand-primary;
      padding-left: 40px;
      width: 40%;
      float: right;
    }
  }
}

.main-footer {
  color:#a5a5a5;
  
  a {
    color:#a5a5a5;
    text-decoration: none;
  }
  
  .wrapper {
    padding-top:10px;
  }
}
