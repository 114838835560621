
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "mixins";
@import "typography";
@import "forms";
@import "header";
@import "footer";
@import "products";
@import "contact";

// Helper Classes
.bg-white {
  background-color: #fff;
}
.bg-light-gray {
  background-color: #f4f4f4;
}

.wrapper {
  max-width:1160px;
  margin:0 auto;
  padding:28px 15px 50px;
  clear:both;
  overflow: hidden;
  position: relative;

  & > div {
    padding-left:0;
    padding-right:0;
  }
}

