.contact-wrapper {
  overflow: hidden;
  margin-top:40px;

  p {
    font-size:120%;
  }
  img {
    float:left;
    margin-right:40px;
    margin-bottom:20px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.5);
  }
  .contact-content {
    float:left;
    h2 {
      margin-top:0;
    }
  }
}
