.main-header {
  position: relative;

  .wrapper {
    padding:0;
  }

  .logo-wrapper {
    text-align: center;
    overflow: hidden;


    .logo-link {
      display:block;
      img {
        max-width: 100px;
        @include respond-to('small') { max-width: 150px; }
        @include respond-to('medium') { max-width: 200px; }
      }

      @include respond-to('small') {
        max-width:150px;
        float:left;
        margin-top:30px;

      }
      @include respond-to('medium') {
        max-width: 200px;
        margin-top: 15px;
      }
    }

    h1 {
      font-size:32px;
      font-weight:700;
      color:#FFF;
      text-shadow: 3px 3px 7px rgba(0,0,0,.5);
      line-height:1;
      margin-top:0;

      @include respond-to('small') {
        float:left;
        width:275px;
        text-align: left;
        margin-top:95px;
      }


      small {
        font-weight:400;
        color:#FFF;
        text-shadow: none;
        font-size:14px;
        display: block;
        margin-top:10px;
      }
    }
  }

  // ----------------------------------------------------------------------------
  // Search Form
  // ----------------------------------------------------------------------------
  .search-form {
    width:40%;
    color:#FFF;
    position:absolute;
    top:96px;
    right:20px;
    display:none;

    @include respond-to('small') {
      display:block;
    }

    .search-field {
      border:none;
      border-collapse: collapse;
      border-bottom:2px solid #1a7bce;
      border-radius: 0;
      background-color:transparent;
      box-shadow: none;
      color:#FFF;
      padding:10px 4px;
      transition:border-bottom-color .5s;
      width: calc(100% - 80px);
      &:focus {
        border-bottom-color:#3a9ef4;
      }
      &::placeholder {
        color: #dddddd;
      }
    }

    button {
      color:#FFF;
      font-weight: bold;
      font-size:26px;
      border-collapse: collapse;
      border:none;
      background-color:transparent;
      cursor:pointer;
    }
  }

  .search-link {
    position: absolute;
    top:10px;
    right:20px;
    display:inline;
    text-decoration: none;
    color:#FFF;
    @include respond-to('small') { display:none; }
  }

  // ----------------------------------------------------------------------------
  // Main Navigation
  // ----------------------------------------------------------------------------
  nav {
    width:100%;
    max-width: 1160px;
    text-align: center;
    margin-top:20px;

    @include respond-to('small') {
      text-align:left;
      padding-left:150px;
    }
    @include respond-to('medium') {
      padding-left:200px;
      margin-top: -10px;
    }


    ul {
      margin:0;
      padding:0;
    }
    li {
      display:inline-block;
      &.active { font-weight: 700; }

      &:nth-child(2) {
        margin:0 20px;
      }

      @include respond-to('medium') {
        &:nth-child(2) {
          margin:0 40px;
        }
      }

      a {
        color:#FFF;
        font-size:16px;
        display: inline;
        transition: color .5s;
        text-decoration: none;
        &:active, &:visited, &:link {
          background-color: transparent !important;
        }

        &:hover {
          background-color: transparent;
          color: #68bbff;
        }

      }
    }
  }
}
