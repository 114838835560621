// ----------------------------------------------------------------------------
// Die Hauptkategorien mit einem Bild und einer kurzen Beschreibung/Link
// ----------------------------------------------------------------------------
.category-list-wrapper {
  margin:0 auto;
  overflow:hidden;
  max-width:368px;

  @include respond-to('small') {
    max-width:750px;
  }

  @include respond-to('medium') {
    max-width: 100%;
  }
}

.category-entry {
  max-width:368px;
  margin:0 auto;
  &:not(.last) {
    margin-bottom:40px;
  }

  @include respond-to('small') {
    width:49%;
    float:left;
    &:nth-of-type(odd) {
      margin-right: 2%;
    }
  }
  //@media screen and (min-width:500px) {
  //  width:31%;
  //  float:left;
  //  margin-bottom:0;
  //  &:nth-of-type(odd) {
  //    clear:left;
  //    margin-right: 2%;
  //  }
  //}
  //
  @include respond-to('medium') {
    width:31%;
    &:not(.last) {
      margin-right: 2%;
    }
    &:nth-of-type(odd) {
      clear:none;

    }
  }

  a:hover {
    text-decoration: none;
    span { color:#135aaf; }
    img { box-shadow: 3px 3px 7px rgba(0,0,0,.8); }
  }

  img {
    box-shadow: 3px 3px 7px rgba(0,0,0,.5);
    display:block;
    margin-bottom:10px;
    transition: box-shadow .5s;
  }
  span {
    font-size:20px;
    color:$brand-primary;
    font-weight:700;
    transition: color .5s;
  }
}

.product-list-wrapper {
  overflow: hidden;
}
.product-list-entry {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding-left:15px;
    text-indent: -15px;
    &::before {
      content: "\2022"; //"•";
      color:$brand-primary;
    }
  }

  @include  respond-to('small') {
    float: left;
    width: 45%;
    &:not(.last) {
      margin-right: 2%;
    }
  }
  @include respond-to('medium') {
    width: 20%;
  }
}

.more-link {
  font-weight:700;
  display: block;
  margin-top:20px;
}

// ----------------------------------------------------------------------------
// Product Category Page (e.g. "Chemicals")
// ----------------------------------------------------------------------------


aside {
  display:block;
  a {
    display:inline;
    white-space: nowrap;
    margin-right:10px;
  }
  .back-link {
    font-weight:700;
    border-right:2px solid $brand-primary;
    padding-right: 10px;
    margin-right: 10px;
  }

  @include respond-to("small") {
    float: left;
    width: 200px;

    a {
      display:block;
      white-space: nowrap;
      margin-bottom:10px;
    }
    .back-link {
      margin-top: 23px;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 24px;
      border-right:none;
    }
  }
}
main {
  @include respond-to('small') {
    float: right;
    width: calc(100% - 200px);
  }
}

.search-form {
  width:100%;
  .search-field {
    width:calc(100% - 80px);
    padding:14px 6px;
    font-size:16px;
  }
  button {
    color: #fff;
    width:60px;
    font-size:24px;
  }
}

// ----------------------------------------------------------------------------
// Product Listing
// ----------------------------------------------------------------------------
.product-listing-wrapper {
  overflow:hidden;
  margin:40px -20px;
}
.product-listing-item {
  padding:20px;
  margin:0;
  //border-top:1px solid #CCC;
  &:nth-of-type(odd) {
    background-color: #F8F8F8;
  }
  h4 {
    margin-top:0;
  }


}

.article-detail {
  overflow: hidden;
  font-size:90%;

  .article-detail-item {
    margin-bottom:10px;
    @include respond-to('small') {
      float: left;
      width: 24%;
      b { display: block; }
    }
  }
}

// Die Produkt Beschreibung auf der Detailseite
.product-description {
  margin:40px 0;
}
