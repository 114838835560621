html, body {
  font-family: $font-family-sans-serif;
  font-size:$font-size-base;
  line-height:$line-height-base;
  color:$text-color;
  padding:0;
  margin:0;
}
h1, h2, h3, h4, h5, h6 {
  color: $brand-primary;
  margin-bottom:.5em;
  line-height: $line-height-base;
}

:focus, :active {
  outline:none;
}
a {
  &:hover, img {
    outline:none;
    border:none;
    text-decoration: none;
  }
}

section {
  a {
    &:link, &:active, &:visited {
      color: $brand-primary;
      transition: color .5s;
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color:#266cbf;
    }
    &.button {
      color:#FFF;
      display:inline-block;
      padding:10px;

      i {
        padding-right:5px;
      }
    }
  }
}

.bigger {
  font-size:120%;
}
.danger {
  color:$brand-danger;
}
.success {
  color:$brand-success;
}

.leadin {
  font-size:18px;
  margin-bottom:40px;

  a {
    display:block;
  }
}
