input[type="text"], input[type="email"], textarea {
  padding:6px;
  font-size:14px;
  border-collapse:collapse;
  border:1px solid #CCC;
  border-radius:4px;
  box-shadow: inset 0 2px 2px rgba(0,0,0,.075);
  width:100%;
  box-sizing:border-box;

  .has-error & {
    border-color:$brand-danger;
    color:$brand-danger;
    background-color: lighten($brand-danger, 50%);
  }
}

.has-error label {
  color:$brand-danger;
}

button, .button {
  color: #fff;
  font-family:$font-family-sans-serif;
  &.with-background {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1b7ed1+0,034ea1+100 */
    background: #0C5EB0; /* Old browsers */
    background: -moz-linear-gradient(top, #0C5EB0 0%, #034ea1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #0C5EB0 0%, #034ea1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #0C5EB0 0%, #034ea1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0C5EB0', endColorstr='#034ea1', GradientType=0); /* IE6-9 */
  }
  border:none;
  border-radius:4px;
  padding:10px 4px;
  box-sizing: border-box;
  border-collapse: collapse;
  vertical-align: top;
  cursor:pointer;
}


fieldset {
  border:none;
  padding:0;
  margin-bottom: 20px;
  padding: 20px 0px;

  @include respond-to('tiny') {
    border: 1px solid #DDD;
    border-radius: 4px;
    padding: 20px 10px;
  }

  legend {
    color: $brand-primary;
    font-weight:bold;
  }
}

form {
  em {
    color:$brand-danger;
  }
}

.field-label-container {
  display:block;
  margin-bottom:10px;

  label {
    font-weight: bold;
    em {
      color:$brand-danger;
    }
  }

  .help-message {
    font-size:90%;
    color:#888;
  }

  @include respond-to('medium') {
    overflow:hidden;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom:0;
    }

    label {
      float:left;
      width:25%;
      padding-top:6px;
    }
    &>label {
      text-align: right;
      padding-right:20px;
      width:calc(25% - 20px);
    }
    .field-container {
      float:left;
      width:75%;
    }
  }
}

.get-a-quote {
  button {
    font-size:16px;
    padding:20px 40px;
    font-weight:bold;
    margin-bottom:0px;
  }
}


.form-validation-errors {
  border:1px solid $brand-danger;
  border-radius: 4px;
  padding: 0 20px 10px;
  margin-bottom:40px;

  h3 {
    color: $brand-danger;
  }
}
